<template>
   <div>
      <b-card title="" style="background-color:rgba(238, 243, 245, 1) !important" class="top-panel row" header="" header-tag="header" id="Vendorrequest">
         <template v-slot:header>
            <span class="mb-0 headertext Create-New-Vendor"  > Create New/Maintain Existing Vendor </span>
         </template>
         <div class="form">
            <div class="d-block row col-lg-5 offset-lg-3">
               <label for="VendorName" class="lbltxt">VENDOR</label>
               <div v-show="!ShowVendorList">
                 <v-select :options="options"   v-model="VendorID" style="background-color:white !important;" placeholder="Enter/Select Vendor or Create New" label="VendorCode" @input="VendorChange">
                    <template #list-footer>
                       <button class="btn btn-lg REQUEST-NEW-INTERAGE" style="background-color:white !important; color:#00A1D2 !important; margin-left:145px !important;" @click="CreateVendor()" >CREATE NEW VENDOR</button>
                    </template>
                 </v-select>
                 <p class="Select-a-billing-par" v-if="!IsVendorSelected">Select a vendor to maintain an existing form or create a new vendor</p>
                 <p class="Select-a-billing-par" v-if="IsVendorSelected">If more changes are required, you must <a class="createvendor"  @click="RequestNewVendor" >create a new vendor </a></p>
               </div>
               <div v-show="ShowVendorList">
                  <input type="text" class="form-control"/>
               </div>
               <br>
               <div v-show="IsVendorSelected">
                  <span class="VendorLineHeader">VENDOR SETUP</span>
                  <hr class="linebreak">
                  <label for="product-code" class="AdditionalText" >Is this vendor related to you or anyone else employed by Omnicom Media Group?</label>
                  <br>
                  <div class="form-group form-check form-check-inline" style="margin-left:18px;">
                     <input type="radio"  value="eulargradeyes" class="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample">
                     <label class="form-check-label" for="materialInline1" style="padding-left:1px;">Yes</label>
                  </div>
                  <div style="margin-left:18px !important;" class="form-group form-check form-check-inline">
                     <input type="radio"  value="eulargradeno" class="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample">
                     <label class="form-check-label" style="padding-left:1px;" for="materialInline2">No </label>
                  </div>
                  <br>
                  <label for="product-code" class="AdditionalText" >Has any form of compensation or gifts of any type been offered to you or any person related to you in exhange for placing an order with this vendor?</label>
                  <div class="form-group form-check form-check-inline" style="margin-left:18px;">
                     <input type="radio"  value="eulargradeyes" class="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample">
                     <label class="form-check-label" for="materialInline1" style="padding-left:1px;">Yes</label>
                  </div>
                  <div style="margin-left:18px !important;" class="form-group form-check form-check-inline">
                     <input type="radio"  value="eulargradeno" class="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample">
                     <label class="form-check-label" for="materialInline2" style="padding-left:1px;">No </label>
                  </div>
                  <br>
                  <label for="VendorName" class="BACKUP-DOCUMENTATION">BACKUP DOCUMENTATION</label>
                  <!-- <FilePond   allowMultiple="true" /> -->
                  <br>
                  <div class="">
                     <label for="hfmclient" class="BACKUP-DOCUMENTATION" > <strong> FEDERAL/SS# </strong> </label>
                     <model-list-select
                        :list="eulargradelevel"
                        option-text="label"
                        option-value="value"
                        placeholder="Select Federal/SS#">
                     </model-list-select>
                  </div>
                  <br>
                  <span class="VendorLineHeader">LEDGER AND MEDIA TYPE</span>
                  <hr class="linebreak">
                  <div class="">
                     <b-form-radio-group
                        id="btn-radios-2"
                        :options="LedgerOptions"
                        buttons
                        button-variant="outline-primary"
                        size="lg"
                        name="radio-btn-outline"
                        class="custom-radio-switch"
                        v-model="selectedledgerval"
                        @change="LedgerChangeEvent">
                     </b-form-radio-group>
                     <div class="form-group Rectangle-Copy1" v-show="ShowMediaBasedonLedger">
                        <div class="form-check" v-show="true" style="padding-top:17px; padding-left:40px;">
                           <input type="checkbox" class="form-check-input" id="exampleCheck1">
                           <label class="form-check-label" for="exampleCheck1"> <strong class="Use-different-remitt"> Digital (includs 1 - interactive,S - Search, L - Social, V - Vedio, W - Programmatic Unbundled, B - Programmatic Bundled)</strong> </label>
                        </div>
                        <br>
                        <div class="form-group">
                           <label for="hfmclient" class="BACKUP-DOCUMENTATION" >MEDIA</label>
                           <model-list-select
                              :list="MediaLists"
                              option-text="label"
                              option-value="value"
                              placeholder="Select Media">
                           </model-list-select>
                        </div>
                        <br>
                        <div class="form-group">
                           <label for="hfmclient" class="BACKUP-DOCUMENTATION" >MEDIA TYPE</label>
                           <model-list-select
                              :list="MediaTypeLists"
                              option-text="label"
                              option-value="value"
                              placeholder="Select Media Type">
                           </model-list-select>
                        </div>
                     </div>
                  </div>
                  <br>
                  <span class="VendorLineHeader">VENDOR INFO</span>
                  <hr class="linebreak">
                  <div class="">
                     <label for="VendorCountry" class="lbltxt">COUNTRY</label>
                     <model-list-select
                        v-model="CountryCode"
                        :list="Countryilst"
                        option-text="label"
                        option-value="value"
                        placeholder="Select Country">
                     </model-list-select>
                  </div>
                  <br>
                  <div class="">
                     <label for="VendorCountry" class="lbltxt" >STREET ADDRESS</label>
                     <input type="text" class="form-control" v-show="CountryCode=='United States1'"  placeholder="Enter Street Address" />
                     <input type="text" class="form-control" v-show="CountryCode!='United States1'"  placeholder="Enter Street Address1" />
                     <br>
                     <input type="text" class="form-control" v-show="CountryCode!='United States1'"  placeholder="Enter Street Address2" />
                  </div>
                  <br>
                  <div class=""  v-show="CountryCode=='United States1'" >
                  <div class="">
                     <label for="VendorCountry" class="lbltxt">CITY</label>
                     <input type="text" class="form-control"  placeholder="Enter City" />
                  </div>
                  <br>
                  <div class="row">
                     <div class="col-md-6">
                        <label for="VendorCountry" class="lbltxt">STATE</label>
                        <model-list-select
                           :list="Statelist"
                           option-text="label"
                           option-value="value"
                           placeholder="Select State">
                        </model-list-select>
                     </div>
                     <div class="col-md-6">
                        <label for="VendorCountry" class="lbltxt">ZIP CODE</label>
                        <input type="text" class="form-control"/>
                     </div>
                  </div>
                  </div>
                  <br>
                  <div class="form-group Rectangle-Copy">
                     <div class="form-check" style="padding-top:17px; padding-left:40px;">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1"> <strong class="Use-different-remitt"> Use different remittance address</strong> </label>
                     </div>
                  </div>
                  <br>
                  <span class="VendorLineHeader">PAYMENT INFO</span>
                  <hr class="linebreak">
                  <label for="product-code" class="AdditionalText" >Pay when paid?</label>
                  <br>
                  <div class="form-group form-check form-check-inline" style="margin-left:18px;">
                     <input type="radio"  value="eulargradeyes" class="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample">
                     <label class="form-check-label" for="materialInline1" style="padding-left:1px;">Yes</label>
                  </div>
                  <div style="margin-left:20px !important;" class="form-group form-check form-check-inline">
                     <input type="radio"  value="eulargradeno" class="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample">
                     <label class="form-check-label" for="materialInline2" style="padding-left:1px;">No </label>
                  </div>
                  <br>
                  <label for="VendorCountry" class="lbltxt">PAYMENT TERMS</label>
                  <model-list-select
                     :list="Paymentterms"
                     option-text="label"
                     option-value="value"
                     placeholder="Select PaymetTerms">
                  </model-list-select>
                  <br>
                  <label for="VendorCountry" class="lbltxt">CURRENCY</label>
                  <model-list-select
                     :list="CurrencyList"
                     option-text="label"
                     option-value="value"
                     placeholder="Select Currency">
                  </model-list-select>
               </div>
            </div>
         </div>
         <br>
         <br>
         <div class="form-group pt-60 text-right">
            <button class="btn btn-link text-uppercase" @click="Cancel()">CANCEL</button>
            <button class="btn custom-button btn-outline-primary" @click="SaveforLater()">SAVE FOR LATER</button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Submit()">SUBMIT</button>
         </div>
      </b-card>
   </div>
</template>
<style>
   .Use-different-remitt {
   width: 223px;
   height: 19px;
   font-family: 'Open Sans';
   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #4d5161;
   }
   .Rectangle-Copy {
   width: 564px;
   height: 65px;
   border-radius: 5px;
   background-color: rgba(216, 216, 216, 0.2);
   }
   .Rectangle-Copy1 {
   width: 515px;
   height: 269px;
   border-radius: 5px;
   background-color: rgba(216, 216, 216, 0.2);
   }
   .card.top-panel > .card-body {
   background-color: transparent !important;
   }
   .AdditionalText {
   width: 500px;
   height: 20px;
   font-family: 'Open Sans' !important;
   font-size: 12px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.43;
   letter-spacing:normal;
   color: #4d5161;
   }
   .BACKUP-DOCUMENTATION {
   width: 229px;
   height: 8px;
   font-family: 'Open Sans';
   font-size: 11px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 0.8;
   letter-spacing: normal;
   color: #4d5161;
   }
   .linebreak {
   width: 594px;
   height: 0px;
   opacity: 0.2;
   border: solid 1px #979797
   }
   .VendorLineHeader {
   width: 110px;
   height: 8px;
   font-family: 'Open Sans';
   font-size: 14px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 0.57;
   letter-spacing: normal;
   color: #77a2bb;
   }
   .createvendor {
   width: 110px;
   height: 8px;
   font-family: 'Open Sans';
   font-size: 14px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 0.57;
   letter-spacing: normal;
   color: #00a1d2 !important;
   cursor: pointer;
   }
   .Select-a-billing-par {
   width: 420px;
   height: 10px;
   font-family: 'Open Sans';
   font-size: 14px;
   font-weight: normal;
   font-stretch: normal;
   font-style: italic;
   line-height: 0.71;
   letter-spacing: normal;
   color: #a2a9ad;
   margin-top:30px;
   margin-left:35px
   }
   .REQUEST-NEW-INTERAGE {
   width: 184px;
   height: 9px;
   font-family: 'Open Sans';
   font-size: 12px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: 0.75;
   letter-spacing: normal;
   color: #00a1d2;
   }
   .lbltxt {
   width: 200px;
   height: 12px;
   font-family: 'Open Sans';
   font-size: 10px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 0.8;
   letter-spacing: normal;
   color: #4d5161;
   }
   .Create-New-Vendor {
   width: 203px;
   height: 27px;
   font-family: 'Open Sans';
   font-size: 20px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: -0.81px;
   color: #4d5161;
   }
</style>
<script>
   import vSelect from 'vue-select'
   import 'vue-select/dist/vue-select.css';
   // import vueFilePond from 'vue-filepond';
   // import 'filepond/dist/filepond.min.css';

   export default {
  watch: {
  },
      data(){
         return{
            CountryCode:null,
            selectedledgerval:'PEH',
            ShowMediaBasedonLedger:false,
            VendorID:null,
            VendorIPForm:false,
            ShowVendorList:false,
            options:[
                      {
                        VendorValue: 'Vendor Code 1',
                        VendorCode: 'Vendor Code 1'
                      },
                      {
                         VendorValue: 'Vendor Code 11',
                         VendorCode: 'Vendor Code 11'
                       },
                      {
                         VendorValue: 'Vendor Code 12',
                         VendorCode: 'Vendor Code 12'
                      },
                 ],

            eulargradelevel:[
              {"label":"2001","value":"2001"},
              {"label":"2002","value":"2002"},
              {"label":"2003","value":"2003"},
              ],
            LedgerOptions: [
               { text: "PRODUCTION/EXPENSE/HOUSE", value: "PEH" },
               { text: "MEDIA VENDOR", value: "MV" },
               { text: "MEDIA REP", value: "MR" }
              ],
           Countryilst:[
              {"label":"India","value":"India"},
              {"label":"United States1","value":"United States1"},
              {"label":"UK","value":"UK"},
             ],
            Statelist:[
               {"label":"State1","value":"State1"},
               {"label":"State2","value":"State2"},
               {"label":"State3","value":"State3"},
            ],
            Paymentterms:[
               {"label":"N007 - Net 7 Days","value":"N007 - Net 7 Days"},
               {"label":"N005 - Net 5 Days","value":"N005 - Net 5 Days"},
               {"label":"N004 - Net 4 Days","value":"N004 - Net 4 Days"},
            ],
            CurrencyList:[
               {"label":"INR","value":"INR"},
               {"label":"Doller","value":"Doller"},
               {"label":"Euro","value":"Euro"},
            ],
             MediaLists:[
               {"label":"NET","value":"NET"},
               {"label":"SPOT","value":"SPOT"},
               {"label":"PRINT","value":"PRINT"},
            ],
             MediaTypeLists:[
               {"label":"MediaTypeA","value":"MediaTypeA"},
               {"label":"MediaTypeB","value":"MediaTypeB"},
               {"label":"MediaTypeC","value":"MediaTypeC"},
            ],
         }
      },

      components:{
           vSelect,
         //   FilePond: vueFilePond()
      },
      computed: {
         IsVendorSelected(){
            return this.VendorID && this.VendorID!="";
         }
      },
      methods:{
         VendorChange(){
            this.VendorIPForm=true;
         },

         RequestNewVendor(){
            this.VendorIPForm=true;
         },

         CreateVendor(){
            this.IsVendorSelected=true;
         },

         Cancel(){
            this.$router.push("/VendorRequests");
         },

         LedgerChangeEvent(arg) {
             if( arg =='MV' || arg == 'MR' )
             {
                this.ShowMediaBasedonLedger=true;
             }
             else
             {
                this.ShowMediaBasedonLedger=false;
             }
         }
      }
   }
</script>